import { Col, Container, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";

export const MainContainer = styled(Container)`
  margin: 0.8rem;
  padding: 0;
  display: contents;
  box-shadow: 0 0 10px 0 #ccc;
`;

export const BodyCard = styled.div`
  margin: 0;
  padding: 0;
  display: block;
  /* max-width: 17rem;
  max-height: 25rem; */
  min-width: 17rem;
  max-width: 17rem;
  min-height: ${(props) =>
    props.theme.customHeight ? props.theme.customHeight : "23rem"};
  max-height: ${(props) =>
    props.theme.customHeight ? props.theme.customHeight : "23rem"};
  background-color: #fff;
  border-radius: 10px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* box-shadow: 5px 5px 10px 3px #ccc; */
  box-shadow: 0px 9px 14px -6px #ccc;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
`;
export const ImageRow = styled(Row)`
  margin-top: -0.4rem;
`;
export const TitleRow = styled(Row)`
  display: flex;
  margin-top: 0.2rem;
`;
export const TitleCol = styled(Col)``;

export const SubTitleRow = styled(Row)`
  margin-top: 0.5rem;
  margin-left: 0rem;
`;
export const SubTitleCol = styled(Col)`
  padding-left: 0.8rem;
`;

export const InfoRow = styled(Row)`
  margin-top: 0.5rem;
`;
export const InfoCol = styled(Col)`
  margin-top: ${(props) => (props.theme.mTop ? props.theme.mTop : "-1rem")};
  /* margin-bottom: ${(props) =>
    props.theme.mTop ? props.theme.mTop : "1rem"}; */
`;

export const RatingCol = styled(Col)`
  width: ${(props) =>
    props.theme.widthPercent ? props.theme.widthPercent : "100%"};
`;

export const HighPriceRow = styled(Row)``;

export const PriceRow = styled(Row)`
  margin-top: 0.5rem;
`;

export const PriceCol = styled(Col)`
  margin: 0;
  padding: 0;
`;

export const HighPrice = styled(Col)`
  margin: 0;
  padding: 0;
`;

export const SkeletonCustom = styled(Skeleton)`
  border-radius: 30px 30px 30px 30px;
`;

export const SkeletonCustomGroup = styled(Skeleton)`
  border-radius: 0.7rem 0.7rem 0.7rem 0.7rem;
`;

export const SkeletonImage = styled(Skeleton)`
  border-radius: 1rem 1rem 0 0;
  padding-top: 1rem;
`;
