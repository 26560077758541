import { Arrow, BtnArrow, SkeletonCol, CustomCarousel } from "./styles";
import Carousel from "react-elastic-carousel";
import { MainCard } from "@components/Cards";
import { SkeletonCard } from "@components/Cards/Skeleton";
import { useBreakpoints } from "@hooks/useBreakpoints";

type CardViewerType = {
  data: MainCardType[];
  isMobile: boolean;
  isLoading: boolean;
};

export const CardViewer: React.FC<CardViewerType> = ({
  data,
  isMobile,
  isLoading,
}) => {
  const { breakpoints } = useBreakpoints();

  const itemShow = (w: string) => {
    if (data && data.length) {
      const aux = data.find((f) => f);
      if (["voyvio"].includes(String(aux?.type))) {
        if (w == "550") {
          return 1;
        }
        if (w == "850") {
          return 2;
        }

        return 3;
      }
    }

    if (w == "550") {
      return 2;
    }
    if (w == "850") {
      return 3;
    }
    return 4;
  };

  const cardBreakpoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: itemShow("550"), itemsToScroll: 1 },
    { width: 850, itemsToShow: itemShow("850"), itemsToScroll: 1 },
    { width: 1150, itemsToShow: itemShow("1150"), itemsToScroll: 1 },
    { width: 1450, itemsToShow: 4, itemsToScroll: 1 },
    { width: 1750, itemsToShow: 6, itemsToScroll: 1 },
  ];

  const myArrow = ({ type, onClick, isEdge }: any) => {
    return (
      <BtnArrow
        onClick={onClick}
        disabled={isEdge}
        theme={{
          isRight: !(type === "PREV"),
          isEdge: isEdge && !isMobile,
          isMobilePage: breakpoints.down("md") ? true : false,
        }}
        variant="light"
      >
        {type === "PREV" ? <Arrow /> : <Arrow theme={{ isRight: true }} />}
      </BtnArrow>
    );
  };

  if (isLoading) {
    return (
      <Carousel
        breakPoints={cardBreakpoints}
        itemsToShow={1}
        isRTL={false}
        renderArrow={myArrow}
        showArrows={data?.length > 3 ? true : false}
        pagination={false}
        disableArrowsOnEnd={true}
        itemPadding={[0, 0, 0, 0]}
        outerSpacing={0}
      >
        {[1, 2, 3, 4].map((s) => {
          return (
            <SkeletonCol key={`skeletonCol-${s}`}>
              <SkeletonCard key={`SkeletonMainCard-carousel-${s}`} />
            </SkeletonCol>
          );
        })}
      </Carousel>
    );
  }
  if (data.length == 0) {
    return <></>;
  }
  return (
    <CustomCarousel
      breakPoints={cardBreakpoints}
      itemsToShow={1}
      isRTL={false}
      renderArrow={myArrow}
      showArrows={
        //data?.length > 3 ? (breakpoints.down("md") ? false : true) : false
        data?.length > 3 ? true : false
      }
      pagination={false}
      disableArrowsOnEnd={true}
      itemPadding={[0, 0, 0, 0]}
      outerSpacing={0}
      theme={{ isMobilePage: breakpoints.down("md") ? true : false }}
    >
      {Object.keys(data[0]).length > 0 &&
        data?.map((info, index) => {
          if (Object.keys(info).length > 0) {
            return <MainCard key={`MainCard-carousel-${index}`} data={info} />;
          }
        })}
    </CustomCarousel>
  );
};
