import { Row } from "react-bootstrap";
import {
  BodyCard,
  InfoCol,
  InfoRow,
  MainContainer,
  SubTitleCol,
  TitleCol,
  TitleRow,
  SubTitleRow,
  // RatingCol,
  HighPriceRow,
  // PriceRow,
  // PriceCol,
  HighPrice,
  SkeletonCustom,
  ImageRow,
  SkeletonImage,
  SkeletonCustomGroup,
} from "./styles";
import { SkeletonTheme } from "react-loading-skeleton";
import { useBreakpoints } from "@hooks/useBreakpoints";

export const SkeletonCard = () => {
  const { breakpoints } = useBreakpoints();

  return (
    <MainContainer>
      <SkeletonTheme baseColor="#D3DDE9" highlightColor="#e1e7ee">
        <BodyCard theme={{ customHeight: "23rem" }}>
          <ImageRow>
            <SkeletonImage duration={1} height={"11rem"} width={"100%"} />
          </ImageRow>
          <TitleRow>
            <TitleCol xs={1} lg={1} />
            <TitleCol xs={8} lg={10}>
              <SkeletonCustom duration={1} height={"1rem"} width={"14rem"} />
            </TitleCol>
            <TitleCol xs={2} lg={1} />
          </TitleRow>
          <SubTitleRow>
            <SubTitleCol xs={4} md={4} lg={4}>
              <SkeletonCustom duration={1} height={"1rem"} width={"6rem"} />
            </SubTitleCol>
            <TitleCol xs={2} lg={2} />
            <SubTitleCol xs={4} md={4} lg={6}>
              <SkeletonCustom duration={1} height={"1rem"} width={"6rem"} />
            </SubTitleCol>
          </SubTitleRow>
          <InfoRow>
            <InfoCol
              theme={{
                mTop: breakpoints.down("md")
                  ? "0rem"
                  : breakpoints.up("md")
                  ? "0.4rem"
                  : "0rem",
              }}
            >
              <HighPriceRow>
                <HighPrice xs={2} md={2} lg={2} />
                <HighPrice xs={3} md={3} lg={3}>
                  <SkeletonCustomGroup
                    duration={1}
                    height={"2rem"}
                    width={"2rem"}
                  />
                </HighPrice>
                <HighPrice xs={5} md={6} lg={6} style={{ marginTop: "0.5rem" }}>
                  <SkeletonCustomGroup
                    duration={1}
                    height={"1rem"}
                    width={"4rem"}
                  />
                </HighPrice>
                <HighPrice sm={1} md={1} lg={1} />
              </HighPriceRow>
            </InfoCol>
            <InfoCol
              theme={{
                mTop: breakpoints.down("md")
                  ? "0rem"
                  : breakpoints.up("md")
                  ? "0.4rem"
                  : "0rem",
              }}
            >
              <HighPriceRow>
                <HighPrice xs={4} md={4} lg={4} />
                <HighPrice xs={5} md={6} lg={6}>
                  <SkeletonCustomGroup
                    duration={1}
                    height={"3rem"}
                    width={"80%"}
                  />
                </HighPrice>
                <HighPrice sm={1} md={1} lg={1} />
              </HighPriceRow>
            </InfoCol>
          </InfoRow>
          <Row style={{ marginTop: "0.3rem" }}>
            <HighPrice xs={2} md={3} lg={3} />
            <HighPrice xs={9} md={8} lg={8}>
              <SkeletonCustom duration={1} height={"1rem"} width={"95%"} />
            </HighPrice>
            <HighPrice sm={1} md={1} lg={1} />
          </Row>
        </BodyCard>
      </SkeletonTheme>
    </MainContainer>
  );
};
